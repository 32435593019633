/* CSS Fixes */
  #gridContainer {
      height: 960px;
  }

  h6[class*="MUIDataTableToolbar-titleText"] {
    font-size: 1.75rem !important;
  }

  div[class*="MUIDataTableFilter-root"] {
    background-color: #f3f3f3 !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #f0f0f0 !important;
    border-radius: 8px !important;
  }

  div[class*="MUIDataTablePagination-navContainer"] {
    line-height: 1 !important;
  }

  .MuiTableCell-root{
    padding: 8px !important;
  }

  div[class*="MUIDataTableHeadCell"] {
    /*font-variant: small-caps;*/
    /*word-break: keep-all;*/
    white-space: pre-wrap !important;
  }

  @media print {
    @page {
      margin: 30px;
      size: A4 landscape;
    }
  }

  .MuiAutocomplete-popper{
    border-radius: 12px 12px 0px 0px;
    background: #fff;
    border: 2px solid #ccc;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.25);
  }
  .MuiAutocomplete-popper[x-placement~="bottom"]{
    border-radius: 0px 0px 12px 12px;
  }


  .red-bar{
    background-color: #800;
    display: block;
    position: fixed;
    top: 0px;
    height: 8px;
    width: 100%;
  }



  .cc.header{
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .cc.header Button{
    margin-right: 10px;
  }

  .cc.header .checkboxes .MuiFormControlLabel-root{
    /*line-height: 0.5;*/
    /*max-height: 15px;*/
    /*padding: 0px;*/
    height: 16px;
  }

  .cc.header .Clock{
    font-size: 30px;
    font-weight: 700;
    margin-right: 15px;
    /*width: 180px;*/
    /*background: red;*/
  }
  .cc.Clock{
    font-size: 15px;
    font-weight: 700;
    margin-right: 15px;
    max-width: 100px;
    min-width: 100px;
    /*background: red;*/
    /*opacity: 0.8;*/
    /*width: 180px;*/
  }
  .cc.header .Metadata{
    font-size: 10px;
    margin-top: 5px;
    font-weight: 400;
  }
  .cc.header .RunID{
    font-size: 15px;
    font-weight: 700;
    display: flex;
    flex-direction: column;  
  }
  .cc.header .RunID{
    line-height: 1;
  }
  .cc.header .clock-and-runid{
    display: flex;
    flex-direction: row;  
    align-items: center;
    background: default;
  }

  .mnb{
    align-items: center;
  }
  .mnb-title{
    opacity: 0.6;
    line-height: 1.1;
    margin-right: 10px;
  }
  .mnb-buttons{
    max-width: 500px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .microbutton{
    font-size: 11px;
    min-width: 90px;
    height: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 5px;
  }
  .microbutton.shape{
    background: #fff;
    color: #000;
    padding: 5px;
    min-height: 22px;
    /*border-radius: 10px;*/
    letter-spacing: 1px;
    width: default;
    min-width: 70px;
    flex-grow: 1;
    font-weight: 600;
    opacity: 0.5;
    /*width: 150px;*/
    text-transform: uppercase;
    cursor: pointer;
  }
  .microbutton.shape.messages{
    background: none;
  }

  .delay-text{
  }
  .microbutton.shape:hover{
    /*background: #ddd;*/
    opacity: 1.0;
  }
  .microbutton.shape .MuiSvgIcon-root{
    /*background: red;*/
    font-size: 20px;
    opacity: 0.5;
    margin-right: 5px;
  }
  .microbutton.shape.messages.blockicon .MuiSvgIcon-root{
    opacity: 1;
  }

  .messagebuttons{
    margin-top: 5px;
  }

  .message-row{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
  }

  .message-row .send-label{
    letter-spacing: 3px;
    font-size: 11px;
    margin-right: 10px;
    opacity: 0.5;
  }

  .microbutton.status-paused{
    cursor: pointer;
  }
  .microbutton.status-closed{
    opacity: 0.4;
    user-select: none;
  }
  .microbutton.status-open{
    cursor: pointer;
  }
  .microbutton .icon{ 
    margin-right: 2px;
    padding-top: 2px;
  }
  .microbutton .icon svg{
    width: 15px;
    height: 15px;  
  }

/* for messages */
  .form-dialog-subtitle{
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.7;
  }


/* Tabs */

  .Mui-selected.MuiTab-fullWidth{
    border-bottom: 6px solid #8c8 !important;
  }

    

/* Product */

  .barcodebox{
    margin: 8px;
    padding: 10px;
    border-radius: 10px;
    width: 140px;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between; 
    box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
  }
  .barcodebox .number{
    margin-top: 5px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 16px;
  }
/* mui-datatables: tables other than tableproduct  */
  .tablecell{
    line-height: 1.4;
    display: flex;
    flex-direction: column;
  }
  .tablecell p{
    /*background: red;*/
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .tablecell.right{
    justify-content: flex-end;
    text-align: right;
  }
  .tablecell.center{
    justify-content: center;
    text-align: center;
  }



  .multi-line-cell{
    line-height: 1.4;
    /*display: flex;*/
    /*flex-direction: column;*/
  }
  .multi-line-cell.gray{
    background: #eee;
  }

  .multi-line-cell p{
    /*background: red;*/
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .multi-line-cell.right{
    /*justify-content: flex-end;*/
    /*text-align: right;*/
  }
  .multi-line-cell.center{
    /*justify-content: center;*/
    /*text-align: center;*/
  }
/* Order */
  .Order{
    box-sizing: border-box;
    padding: 10px;
  }

  .Order .filter-dates .MuiRadio-root{
    padding: 3px;
  }

  .Order .many-droplists{
    max-width: 400px;
    /*max-height: 120px;*/
  }

  .Order th{
    line-height: 1.1;
  }

  .orderheading{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .orderheading .num{
    border: 3px solid black;
    padding: 2px 6px 2px 6px;
    font-size: 20px;
    font-weight: bold;
    background: #fff;
    margin-left: 20px;
    border-radius: 10px;
  }
  .headbubble{
    border: 3px solid rgba(0,0,0,0.15);
    padding: 2px 6px 2px 6px;
    font-size: 20px;
    font-weight: bold;
    background: #fff;
    margin-left: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    height: 38px;
    align-items: center;
  }
  .headbubble.startend{
    font-size: 12px;
    /*font-weight: 300;*/
    border: 3px solid rgba(0,0,0,0);
    color: rgba(0,0,0,0.8);
    /*border: none;*/
    background: rgba(0,0,0,0.2);
    /*background: red;*/
    flex-direction: column;
  }
  .headbubble.startend div{
    /*background: orange;*/
    margin-bottom: -2px;
    margin-top: -1px;
    margin-right: 4px;
  }

  .headbubble svg{
    /*margin-bottom: -5px;*/
    margin-right: 3px;
    opacity: 0.3;
  }
  .headbubble.startend svg{
    margin-bottom: -3px;
    height: 15px;
    /*display: none;*/
    /*margin-right: 3px;*/
    color: #000;
    /*background: blue;*/
    /*opacity: ;*/
  }

  .headbubble.headstatus{
    border: 3px solid rgba(0,0,0,0.0);
    color: #fff;
    background: #888;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 300;
  }

  .headbubble .multi{
    /*background: red;*/
    font-size: 12px;
    max-width: 150px;
    line-height: 1.1;
    opacity: 0.7;
    text-overflow: wrap;
  }
  .headbubble.smaller{
    font-size: 14px;
  }
  .headbubble.smaller svg{
    width: 20px;
  }
  .smaller svg{
    width: 20px;
  }
  .even-smaller svg{
    height: 15px;
    width: 15px;
  }

  .orderheading .postcode{
    border: 3px solid #fff;
    background: #fff;
    padding: 2px 4px 2px 4px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
    border-radius: 10px;
    color: #888;
  }
  .orderheading .tourid{
    border: 3px solid #fff;
    background: #fff;
    padding: 2px 4px 2px 4px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
    border-radius: 10px;
    color: #888;
  }
  .orderheading .status{
    padding: 2px 4px 2px 4px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    /*margin-right: 10px;*/
    border-radius: 10px;
  }
  .orderheading .network{
    padding: 2px 4px 2px 4px;
    border: 3px solid #fff;
    background: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
    /*border-radius: 10px;*/
  }

  .Order .time{
    margin-left: 20px;
    display: flex;
    align-items: center;

  }
  .Order .time .left,
  .Order .time .right,
  .Order .time .mid{
    display: flex;
    font-size: 16px;
    background: #fff;
    font-weight: bold;
    color: #999;
    align-items: center;
    border: 3px solid #999;
    height: 38px;
    padding: 5px;
  }
  .Order .time .left{
    padding: 5px 3px 5px 5px;
    border-radius: 10px 0px 0px 10px;
  }
  .Order .time .right{
    padding: 5px 8px 5px 20px;
    border-radius: 0px 10px 10px 0px;
    margin-left: -18px;
  }

  .Order .time .mid{
    margin-left: 0px;
    font-size: 16px;
    padding-left: 15px;
    border: 3px solid #999;  
    z-index: 1;
    margin-left: -15px;
    margin-right: -5px;
    border-radius: 0px;
    display: flex;
    background: #999;
    font-weight: bold;
    color: #fff;
    align-items: center;
  }

  .Order .left-triangle,
  .Order .right-triangle{
    width: 0px;
    height: 0px;
    z-index: 3;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
  }
  .Order .left-triangle{
    margin-left: -4px;
    border-left: 17px solid #fff;
  }
  .Order .right-triangle{
    margin-left: 5px;
    border-left: 17px solid #999;
  }

  .order-plusminus{
    display: flex;
    flex-direction: row;
    /*background: red;*/
    align-items: center;
  }
  .order-plusminus .count{
    font-size: 20px;
  }
  .order-plusminus .minus{
    /*opacity: 0.3;*/
  }

  .cart-footer{
    font-size: 20px;
    /*padding-top: 20px;*/
  }
  .cart-footer.right{
    padding-right: 12px;
  }
/* tour.js  */
  .PanelOrders-Card{
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .panel-orders{
    /*height: 100%;*/

    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  .panel-orders .order-header{

  }
  .panel-orders .order-list{

    display: flex;
    height: 700px;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }

  .panel-orders .order{
    cursor: pointer;
    min-height: 105px;
    background: #fff;
    padding: 5px;
    margin: 5px;
    margin-bottom: 10px;

    border: 2px solid #ccc;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);

  }

  .panel-orders .order .time-orange{
    font-weight: 600;
    color: orange;
    margin-right: 5px;
  }

  .panel-orders .order .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .panel-orders .order table .green{
    background: #afa;
  }

  .panel-orders .order table,
  .panel-orders .order th,
  .panel-orders .order td{
    border: 0;
    margin: 0;
    border-collapse: collapse;
    border-spacing: 0;
    padding: 3px;
  }
  .panel-orders .order table{
    margin-top: 5px;
    width: 100%;
  }
  .panel-orders .order table thead td{
    background: #eee;
  }
  .panel-orders .order table td.line{
    border-right: 3px solid #eee;
  }


/* MAP! */
  .map.latest-position{
    display: flex;
    flex-direction: row;
    position: absolute;
    top: -40px;
    left: -20px;
  }
  .map.latest-position .label{
  /*  background: pink;
    border-radius: 50%;
    min-width: 80px;
    min-height: 80px;*/

    /*position: absolute;*/
    display: flex;
    width: 100px;
    /*height: 40px;*/
    left: -20px;
    top: -20px;
    /*border: 5px solid #f44336;*/
    border-radius: 10px;
    background-color: rgba(255,255,255,0.8);
    text-align: left;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    padding: 8px;
  }

  .map.latest-position .icon{
    /*background-color: rgba(255,255,255,0.8);*/
    width: 40px;
    height: 40px;
  }
/* Strength */

  .strength{
    display: flex;
    flex-direction: row;
  }
  .strength .dots{
    opacity: 0.3;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
  }
  .strength .label{
    opacity: 0.8;
  }
  .strength .dots svg{
    padding: 4px;
  }
/* Dashboard */
  .login-logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .login-logo img{
    max-width: 100px;
  }
  .dash-heading{
    font-weight: 100;
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
    opacity: 0.4;
  }
  .dashboard-logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20px;
    background: #fff;
  }  
  .dashboard-logo img{
    max-width: 100px;
  }

/* Opening Times */
  table.opening-times {
    border: 3px;
  }
  table.opening-times td{
    padding: 3px;
  }
  table.opening-times thead{
    font-weight: bold;
  }
/* Global */

  .smaller-icon svg{
    height: 16px;
    margin-bottom: -3px;
  }

  .smaller{
    /*font-size: 10px;*/
    font-size: 80%;
  }
  .even-smaller{
    /*font-size: 10px;*/
    font-size: 60%;
  }

  .validinput{
    font-weight: bold;
    padding: 3px 6px 3px 6px;
    border-radius: 5px;
    background: #fff;
    color: #080;
  }
  .non-validinput{
    padding: 3px 6px 3px 6px;
    font-weight: bold;
    border-radius: 5px;
    background: #800;
    color: #fff;
  }

  .noimage-50-50{
    display: flex;
    background: #fff;
    width: 50px;
    height: 50px;
  }

  .fixed-progress-bar{
    /*background: red;*/
    position: fixed;
    width: 100%;
  }

  .avatar{
    /*background: red; */
  }

  .grid.row{
    display: flex;
    flex-direction: row;
  }
  .grid.row.even{
    flex-grow: 1;
    flex-shrink: 1;
  }
  .grid.col{
    display: flex;
    flex-direction: column;
  }
  .grid.col.even{
    flex-grow: 1;
    flex-shrink: 1;
  }
  .grid .mleft5{
    margin-left: 5px;
  }
  .grid .mleft10{
    margin-left: 10px;
  }
  .grid {
    /*margin-bottom: 20px;*/
  }
  .dimmer{
    opacity: 0.66;
  }
  .thinner{
    font-weight: 300;
  }
  .even-dimmer{
    opacity: 0.25;
  }
  .bigger{
    font-size: 130%;
    font-weight: bold;
  }
  .grid .mbottom0{
    margin-bottom: 0;
  }
  .mbottom10{
    margin-bottom: 10px;
  }
  .mbottom5{
    margin-bottom: 5px;
  }
  .mtop20{
    margin-top: 20px;
  }
  .mtop50{
    margin-top: 50px;
  }
  .mtop5{
    margin-top: 5px;
  }
  .rules{
    padding-left: 4px;
    line-height: 20px;
    opacity: 0.7;
  }
  .width50percent{
    width: 50%;
  }
  .grid .mtop20{
    margin-top: 20px;
  }
  .grid .mright5{
    margin-right: 5px;
  }
  .grid .mright10{
    margin-right: 10px;
  }

  .grid .vcenter{
    align-items: center;
  }

  .grid.col.spacebetween{
    align-content: space-between;
  }
  .grid.row.spacebetween{
    justify-content: space-between;
  }

  .grid.mid{
    display: flex;
    align-items: center;
  }


  .alerty{
    border-radius: 5px;
    background: linear-gradient(0deg, rgba(255,186,0,1) 0%, rgba(255,156,0,1) 100%);
    padding: 2px 7px 2px 7px;
    font-size: 11px;
    font-weight: bold;
  }


  /* hack to overcome silly layout problem*/
  .cc.root{
    margin: 2px; 
  }



